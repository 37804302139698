<template>
	<b-card no-body class="mb-0">
		<!-- Filtrado -->
		<div class="m-2">
			<b-row>
				<b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
					<label>Mostrar</label>
					<v-select class="per-page-selector d-inline-block mx-50"
						v-model="perPage"
						:options="pageOptions"
						:clearable="false"
					/>
					<label>registros</label>
				</b-col>
				<b-col cols="12" md="6">
					<div class="d-flex align-items-center justify-content-end">
						<b-input-group class="mr-1">
							<b-form-input
								type="search"
								placeholder="Buscar..."
								v-model="filter"
							/>
							<b-input-group-append>
								<b-button class="p-0 px-1"
									variant="secondary"
									:disabled="!filter"
									@click="filter = '';"
								>
									<feather-icon icon="XIcon" size="21"/>
								</b-button>
							</b-input-group-append>
						</b-input-group>
						<b-button
							variant="primary"
							:to="{ name: 'movimientos-cambio-plaza-agregar' }"
						>
							<span class="text-nowrap">Nuevo</span>
						</b-button>
					</div>
				</b-col>
			</b-row>
		</div> <!-- Filtrado -->

		<b-table
			stacked="md"
			show-empty
			small
			class="position-relative"
			empty-text="No se encontraron registros"
			:items="data"
			:fields="columns"
	      :current-page="currentPage"
   	   :per-page="perPage"
			:filter="filter"
			@filtered="onFiltered"
		>
			<template v-slot:cell(index)="data">
				<b-button variant="secondary" size="sm" class="mr-1" title="Usuarios"
					v-b-modal.modal-usuarios>
					<feather-icon icon="UsersIcon" size="18"/>
				</b-button>
				<b-button variant="secondary" size="sm" class="mr-1" title="Opciones"
					v-b-modal.modal-opciones>
					<feather-icon icon="CheckSquareIcon" size="18"/>
				</b-button>
			</template>

			<template #cell(estatus)="data">
				<b-badge pill
					:variant="`light-${estatusText(data.item.estatus)}`"
					class="text-capitalize"
				>
					{{ data.item.estatus === "1" ? "Activo" : "Inactivo" }}
				</b-badge>
			</template>
			<template #cell(acciones)="data">
				<div class="text-nowrap">
					<b-dropdown
						variant="link"
						no-caret
						:disabled="data.item.estatus === '0'"
					>
						<template #button-content>
							<feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body"/>
						</template>
						<b-dropdown-item
							:to="{name: 'administracion-roles-editar', params: { id: data.item.id }, }"
						>
							<feather-icon icon="EditIcon" class="text-warning" />
							<span class="align-middle ml-50">Editar</span>
						</b-dropdown-item>
						<b-dropdown-item  @click="desactivarRol(data.item)">
							<feather-icon icon="TrashIcon" class="text-danger" />
							<span class="align-middle ml-50">Eliminar</span>
						</b-dropdown-item>
					</b-dropdown>
				</div>
			</template>
		</b-table>

		<!-- Paginacion -->
		<div class="mx-2 mb-2">
			<b-row>
				<b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
					<span class="text-muted">
						Mostrando {{ dataMeta.from }} de {{ dataMeta.to }} de {{ dataMeta.of }} registros
					</span>
				</b-col>
				<b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
					<b-pagination class="mb-0 mt-1 mt-sm-0"
						v-model="currentPage"
						:total-rows="totalRows"
						:per-page="perPage"
						first-number
						last-number
						prev-class="prev-item"
						next-class="next-item"
					>
						<template #prev-text>
							<feather-icon icon="ChevronLeftIcon" size="18" />
						</template>
						<template #next-text>
							<feather-icon icon="ChevronRightIcon" size="18" />
						</template>
					</b-pagination>
				</b-col>
			</b-row>
		</div> <!-- Paginacion -->

		<b-modal id="modal-usuarios"
			title="Agregar usuarios"
			size="lg"
		>
			<p class="my-4">Usuarios</p>
		</b-modal>
		<b-modal id="modal-opciones"
			title="Agregar opciones"
			size="lg"
		>
			<p class="my-4">Opciones</p>
		</b-modal>

	</b-card>
</template>

<script>
	import {
		BTab, BTabs, BCard, BAlert, BLink, BForm, BFormGroup, BFormInput, BTable, BBadge,
		BModal, VBModal,
		BDropdown, BDropdownItem, BPopover, BInputGroup, BInputGroupAppend, BPagination,
		BFormTextarea, BFormInvalidFeedback, BButton, BFormSelect, BRow, BCol, BFormCheckbox
	} from 'bootstrap-vue'
   import axios from "@axios"
	import router from "@/router";
	import { ValidationObserver, ValidationProvider, } from "vee-validate";
	import { required, alphaNum, email, numeric } from "@validations";
	import vSelect from "vue-select";
   import utilerias from '@/views/utilerias'

	const {
		msgToast,
		msjConfirmarDesactivar,
	} = utilerias()

	export default {
		components: {
			BTab, BTabs, BCard, BAlert, BLink, BForm, BFormGroup, BFormInput, BTable, BBadge,
			BModal, VBModal,
			BDropdown, BDropdownItem, BPopover, BInputGroup, BInputGroupAppend, BPagination,
			BFormTextarea, BFormInvalidFeedback, BButton, BFormSelect, BRow, BCol, BFormCheckbox,
			vSelect,
			ValidationProvider,
			ValidationObserver,
		},
		data() {
			return {
				data: null,
				columns: [
					{key: 'index', label: ' '},
					{ key: 'nombre_rol', label: 'Nombre', sortable: true },
					{ key: 'estatus', label: 'Estatus', sortable: true },
					{ key: 'acciones' },
				],
				totalRows: 0,
				currentPage: 1,
				perPage: 5,
				pageOptions: [5, 10, 25, 50, 100],
				filter: null,
			};
  		},
		directives: {
			'b-modal': VBModal,
		},
  		computed: {
			dataMeta() {
				return {
					from: this.perPage * (this.currentPage - 1) + 1,
					to: (this.perPage*this.currentPage) < this.totalRows ? this.perPage * this.currentPage : this.totalRows,
					of: this.totalRows,
				}
    		}
  		},
		async mounted() {
			await this.cargaRoles();
   	   this.totalRows = this.data.length
		},
  		methods: {
			estatusText(status) {
				if (status === '1') return 'success'
				if (status === '0') return 'danger'
				return 'secondary'
			},
      	onFiltered(filteredItems) {
				this.currentPage = 1
				this.totalRows = filteredItems.length
			},
   		getValidationState({ dirty, validated, valid = null }) {
				return dirty || validated ? valid : null;
			},
    		async cargaRoles() {
				const r = await axios.get(`/api/cat-roles?q='*'`, { withCredentials: true })
				this.data = r.data.data;
    		},
			desactivarRol(item) {
				msjConfirmarDesactivar.fire({}).then(async (result) => {
					if (result.isConfirmed) {
						const r = await axios.delete(`/api/cat-roles/${item.id}`, { withCredentials: true })
						msgToast.fire({ icon: 'success', title: 'Rol desactivado' });
						await this.cargaRoles();
					}
				});
			}
  		}
	};
</script>

<style>
	.content-header .breadcrumb {
		display: flex !important;
	}
</style>
